<template>
    <div id="content">
        <div class="container3D turn">
            <div class="wrapper3D"></div>
            <div class="slide3D-pagination"></div>
            <div class="slide3D-prev-button"></div>
            <div class="slide3D-next-button"></div>
        </div>
    </div>
</template>
<script>
import './slideshow';
import banner2 from "@/assets/banner2.png"
import banner3 from "@/assets/banner3.png"
export default {
    data() {
        return {

        };
    },

    mounted() {
        this.onLoad();
    },

    methods: {

        onLoad() {
            var width = 99;
            var height = 39;
            let mySlide3 = new Slide3D(".turn", {
                width: width,
                height: height,
                effect: "turn",
                sources: [
                banner2,
                banner3,
                ],
                box: {
                    rows: 6, // 切割行
                    cols: 3, // 切割列
                },
                pagination: false, // 是否添加分页器
                paginationClickable: true,
                autoplay: 5000,
                // autoplayDisableOnInteraction : false
            })
        }

    },
};
</script>
<!-- <script src="./slideshow.js"></script> -->

<style lang="scss" scoped>
@import "./slideshow.css";
</style>