<template>
  <div id="app">
    <Header />
    <router-view class="main_container" />
    <Footer />
    <!-- <Code /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer/Footer.vue";
import Code from "@/components/Code/Code.vue";
export default {
  name: "app",
  components: {
    Header,
    Footer,
    Code,
  },
};
</script>

<style lang="scss">
body{
  padding: 0;
  margin: 0;
}
.main_container {
  padding-top: 67px;
  min-height: 300px;
}
</style>
