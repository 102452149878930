<template>
  <div class="footer_container" >
    <div class="item1">
      <img src="@/assets/bottom.png" >
    </div>
    
    <!-- <el-image :src="require('@/assets/bottom.png')" style="width:100%;height:260px;min-width:100%" fit="none"></el-image> -->
    <!-- <div class="footer_wrap">
      
      <div class="footer_info">
        <div class="text_title">商务合作邮箱：</div>
        <div class="text_email">lvqin@usharejob.com</div>
        <div class="text_company">
          Copyright © 2021 All Rights Reserved
          Poweredby嘉兴优享零工信息技术有限公司 ICP备案：浙ICP备20029444号-1
        </div>
      </div>
      <div class="footer_code_wrap">
        <div class="code_image">
          <el-image
            fit="contain"
            src="https://cdn.usharejob.com/uploads/images/2022/02/23/5dccaa85fea1c7e43d0d5cf58f70b1cc.png"
          ></el-image>
          <div class="image_text">关注公众号</div>
        </div>
        <div class="code_image">
          <el-image
            fit="contain"
            src="https://cdn.usharejob.com/uploads/images/2022/02/23/877ba17d1233cec78c0ab02bf333dd1f.png"
          ></el-image>
          <div class="image_text">客服微信</div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      activeIndex: "/",
    };
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./Footer.scss";
</style>
