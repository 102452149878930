<template>
  <div class="home_container">
    <div class="image_item default">
      <Slideshow />
    </div>
    <div class="default_one">
      <div class="product_left">
        <div class="phone">
          <img src="@/assets/优享零工.png" alt="" v-show="flag[1]" :class="[productIndex == '1' ? 'ap' : '']">
          <img src="@/assets/灵薪卡.png" alt="" v-show="flag[2]" :class="[productIndex == '2' ? 'ap' : '']">
          <img src="@/assets/优禾邻里.png" alt="" v-show="flag[3]" :class="[productIndex == '3' ? 'ap' : '']">
          <img src="@/assets/优聘LIVE.png" alt="" v-show="flag[4]" :class="[productIndex == '4' ? 'ap' : '']">
          <img src="@/assets/期待优享.png" alt="" v-show="flag[5]" :class="[productIndex == '5' ? 'ap' : '']">
        </div>
        <div class="constellation" @mouseleave="mouseoutProduct">
          <div :class="[productIndex == '2' ? 'part-1 constellation-part activePart' : 'part-1 constellation-part']"
            @mouseover="product('2')" @click="clickProduct('2')"></div>
          <div :class="[productIndex == '3' ? 'part-2 constellation-part activePart' : 'part-2 constellation-part']"
            @mouseover="product('3')" @click="clickProduct('3')"></div>
          <div :class="[productIndex == '4' ? 'part-3 constellation-part activePart' : 'part-3 constellation-part']"
            @mouseover="product('4')" @click="clickProduct('4')"></div>
          <div :class="[productIndex == '5' ? 'part-4 constellation-part activePart' : 'part-4 constellation-part']"
            @mouseover="product('5')" @click="clickProduct('5')"></div>
          <div class="part-5 constellation-part"></div>
          <div class="part-6 constellation-part"></div>
          <div class="part-7 constellation-part"></div>
          <div class="part-8 constellation-part"></div>
          <div class="part-9 constellation-part"></div>
          <div :class="[productIndex == '1' ? 'part-10 constellation-part activePart' : 'part-10 constellation-part']"
            @mouseover="product('1')" @click="clickProduct('1')"></div>
          <div class="part-13" @mouseenter="mouseoutProduct">
            <div class="centerIcon1" v-show="productIndex == '1'">
              <img src="@/assets/优享零工icons.png" alt="">
            </div>
            <div class="centerIcon2" v-show="productIndex == '2'">
              <img src="@/assets/灵薪卡icons.png" alt="">
            </div>
            <div class="centerIcon3" v-show="productIndex == '3'">
              <img src="@/assets/优禾邻里icons.png" alt="">
            </div>
            <div class="centerIcon4" v-show="productIndex == '4'">
              <img src="@/assets/优聘LIVEicons.png" alt="">
            </div>
            <div class="centerIcon5" v-show="productIndex == '5'">
              <img src="@/assets/iocn期待优享.png" alt="">
            </div>
          </div>
          <div class="part-14" @mouseenter="mouseoutProduct"></div>
          <div :class="[productIndex == '1' ? 'icon-1 activeIcon' : 'icon-1']" @mouseover="product('1')"
            @click="clickProduct('1')"><img src="@/assets/优享零工icon.png" alt=""></div>
          <div :class="[productIndex == '2' ? 'icon-2 activeIcon' : 'icon-2']" @mouseover="product('2')"
            @click="clickProduct('2')"><img src="@/assets/灵薪卡icon.png" alt=""></div>
          <div :class="[productIndex == '3' ? 'icon-3 activeIcon' : 'icon-3']" @mouseover="product('3')"
            @click="clickProduct('3')"><img src="@/assets/优禾邻里icon.png" alt=""></div>
          <div :class="[productIndex == '4' ? 'icon-4 activeIcon' : 'icon-4']" @mouseover="product('4')"
            @click="clickProduct('4')"><img src="@/assets/优聘LIVEicon.png" alt=""></div>
          <div :class="[productIndex == '5' ? 'icon-5 activeIcon' : 'icon-5']" @mouseover="product('5')"
            @click="clickProduct('5')"><img src="@/assets/期待优享Icon.png" alt=""></div>
        </div>
      </div>
      <div class="product_right">
        <div class="title">我们的产品 <span></span> </div>
        <div class="introductory">
          <!-- 优享零工 -->
          <div v-show="productIndex == '1'" :class="[productIndex == '1' ? 'active_product_right' : '']">
            <div class="titleIcon">
              <img src="@/assets/标题icon.png" alt="">
              <span>优享零工</span> ·灵活就业蓄水池
            </div>
            <div class="introductoryText">
              <div>
                零工经济时代正在到来。<br>
                中国的零工经济能容纳2亿人的就业，仅2022年，市场规模将会突破1.3万亿元。随着零工的概念进一步扩大和丰富，对标发达国家35%的灵活用工占比，中国目前仅10%，零工经济未来增长空间巨大。
              </div>
              <div class="texMargin">
                -3公里内，附近优职智能匹配与推送<br>
                -嘉兴本土10W注册用户，日结500单<br>
                -独创视频招聘功能，轻松找工作、快速洞察先机，一键报名和分享<br>
                -全程跟踪服务，从报名到结算全链路解决，创造安全、美好的灵活就业环境
              </div>
              <div class="introductoryCode">
                <div><img src="@/assets/优享零工code.png" alt="">
                  <p>微信扫码体验</p>
                </div>

              </div>
            </div>
          </div>
          <!-- 灵薪卡 -->
          <div v-show="productIndex == '2'" :class="[productIndex == '2' ? 'active_product_right' : '']">
            <div class="titleIcon">
              <img src="@/assets/标题icon.png" alt="">
              <span>灵薪卡</span> ·灵工财税专家
            </div>
            <div class="introductoryText">
              <div>
                针对灵活用工后道环节的交易发佣工具，包括交易、结算、支付及完税的一系列财务及操作繁杂的问题，
                通过与产业园合作，优化零工就业到结算链路，实现零工交易全链路线上完成，真正解决零工从报名到结算的效率问题和财务问题。
              </div>
              <div class="texMargin">
                -对接支付宝、银行等多种发薪通道，方便企业灵活切换交易通道<br>
                -创新采用项目经理先支付，灵工后提现的方式，彻底解决灵工结算难、信息收集难的问题<br>
                -多功能码快速签约、无感认证、智慧结算、财务对账、实时到账等功能，让结算高效且安全
              </div>
              <div class="introductoryCode">
                <div><img src="@/assets/灵薪卡code.png" alt="">
                  <p>微信扫码体验</p>
                </div>
              </div>
            </div>
          </div>
          <!-- 优禾邻里 -->
          <div v-show="productIndex == '3'" :class="[productIndex == '3' ? 'active_product_right' : '']">
            <div class="titleIcon">
              <img src="@/assets/标题icon.png" alt="">
              <span>优禾邻里</span> ·社区里的灵工管家
            </div>
            <div class="introductoryText">
              <div>
                优禾邻里作为社区里的灵工管家，有天然的信任基础和地理优势，专注解决社区周边商户的灵工供需对撮，让你身边的灵工随处可见，随时随地利用碎片时间挣钱，实现人与任务的完美匹配。
              </div>
              <div class="texMargin">
                -新灵工应运而生，在优禾邻里你能看到广泛而鲜活的泛生活类灵活需求
                <br>
                -通过社区网格化营运，让每一个需求得以快速响应和落地服务
                <br>
                -在附近社区找到临时看护、遛狗员、跑腿、开锁师傅等不仅便宜能效高，更让你放心安心
              </div>
              <div class="introductoryCode">
                <div><img src="@/assets/优禾邻里code.png" alt="">
                  <p>微信扫码体验</p>
                </div>
              </div>
            </div>
          </div>
          <!-- 优聘LIVE -->
          <div v-show="productIndex == '4'" :class="[productIndex == '4' ? 'active_product_right' : '']">
            <div class="titleIcon">
              <img src="@/assets/标题icon.png" alt="">
              <span>短视频+直播营销</span>
            </div>
            <div class="introductoryText">
              <div>
                将电商带货营销思维注入招聘拓“客”
                <br>
                招聘信息快速引爆全城
                <br>
                雇主品牌营销、人才获取，双重效应
              </div>
              <!-- <div class="texMargin">
                -校园招聘专区，对接各大名企，实现企校联动，并提供直投通道
                <br>
                -专场直播招聘，由主播或企业HR带你了解企业，在线互动直播和即时答疑，找工作不盲目
                <br>
                -让求职者每一次找工作，身临其境，所见即所得
              </div> -->
              <div class="introductoryCode">
                <div><img src="@/assets/优聘LIVEcode.png" alt="">
                  <p>微信扫码体验</p>
                </div>
              </div>
            </div>
          </div>
          <!-- 期待优享 -->
          <div v-show="productIndex == '5'" :class="[productIndex == '5' ? 'active_product_right' : '']">
            <div class="titleIcon">
              <img src="@/assets/标题icon.png" alt="">
              <span>2023</span> ·期待优享
            </div>
            <div class="introductoryText">
              <div>
                我们致力于帮助企业解决复杂的灵活用工问题
                <br>
                提供从人力招聘、雇主品牌、灵活雇佣到薪酬财税管理的组合产品
              </div>
              <div class="texMargin expectImg">
                <div class="oneImg">

                  <span>电商物流</span>
                  <img src="@/assets/电商.png" alt="">
                </div>
                <div class="twoImg">

                  <span>服务型企业</span>
                  <img src="@/assets/服务.png" alt="">
                </div>
                <div class="threeImg">

                  <span>平台兼职</span>
                  <img src="@/assets/去兼职.png" alt="">
                </div>
              </div>
              <!-- <div class="introductoryCode">
                <div><img src="@/assets/优聘LIVEcode.png" alt="">
                  <p>微信扫码体验</p>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="default_two"  >
      <div class="title">
        我们的优势
      </div>
      <div class="container" style="background-color:#262626 ;">
        <ul >
          <li :class="[activeIndex == '1' ? 'acitveLi' : '']" @mouseover="arrival('1')" @click="clickBox('1')">
            <img src="@/assets/48小时极速招募.png" v-show="activeIndex!=1">
            <img src="@/assets/48小时极速招募2.png" >
          </li>
          <li :class="[activeIndex == '2' ? 'acitveLi' : '']" @mouseover="arrival('2')" @click="clickBox('2')">
            <img src="@/assets/全国优享人才库.png" v-show="activeIndex!=2">
            <img src="@/assets/全国优享人才库2.png">
          </li>
          <li :class="[activeIndex == '3' ? 'acitveLi' : '']" @mouseover="arrival('3')" @click="clickBox('3')">
            <img src="@/assets/低成本进入市场.png" v-show="activeIndex!=3">
            <img src="@/assets/低成本进入市场2.png">
          </li>
          <li :class="[activeIndex == '4' ? 'acitveLi' : '']" @mouseover="arrival('4')" @click="clickBox('4')">
            <img src="@/assets/雇佣形式灵活.png" v-show="activeIndex!=4">
            <img src="@/assets/雇佣形式灵活2.png">
          </li>
          <li :class="[activeIndex == '5' ? 'acitveLi' : '']" @mouseover="arrival('5')" @click="clickBox('5')">
            <img src="@/assets/灵活财税管理.png" v-show="activeIndex!=5">
            <img src="@/assets/灵活财税管理2.png">
          </li>
          <li :class="[activeIndex == '6' ? 'acitveLi' : '']" @mouseover="arrival('6')" @click="clickBox('6')">
            <img src="@/assets/3公里精准触达.png" v-show="activeIndex!=6">
            <img src="@/assets/3公里精准触达2.png">
          </li>
        </ul>
      </div>
      <!-- <div class="items">
        <div :class="[activeIndex == '1' ? 'item active_item' : 'item']" @mouseover="arrival('1')" @click="clickBox('1')">
          <div v-if="activeIndex == '1'">
            <img src="@/assets/48小时极速招募2.png" alt="">
          </div>
          <div v-else> <img src="@/assets/48小时极速招募.png" alt=""></div>
        </div>
        <div :class="[activeIndex == '2' ? 'item active_item' : 'item']" @mouseover="arrival('2')" @click="clickBox('2')">
          <div v-if="activeIndex == '2'">
            <img src="@/assets/全国优享人才库2.png" alt="">
          </div>
          <div v-else> <img src="@/assets/全国优享人才库.png" alt=""></div>
        </div>
        <div :class="[activeIndex == '3' ? 'item active_item' : 'item']" @mouseover="arrival('3')" @click="clickBox('3')">
          <div v-if="activeIndex == '3'">
            <img src="@/assets/低成本进入市场2.png" alt="">
          </div>
          <div v-else> <img src="@/assets/低成本进入市场.png" alt=""></div>
        </div>
        <div :class="[activeIndex == '4' ? 'item active_item' : 'item']" @mouseover="arrival('4')" @click="clickBox('4')">
          <div v-if="activeIndex == '4'">
            <img src="@/assets/雇佣形式灵活2.png" alt="">
          </div>
          <div v-else> <img src="@/assets/雇佣形式灵活.png" alt=""></div>
        </div>
        <div :class="[activeIndex == '5' ? 'item active_item' : 'item']" @mouseover="arrival('5')" @click="clickBox('5')">
          <div v-if="activeIndex == '5'">
            <img src="@/assets/灵活财税管理2.png" alt="">
          </div>
          <div v-else> <img src="@/assets/灵活财税管理.png" alt=""></div>
        </div>
        <div :class="[activeIndex == '6' ? 'item active_item' : 'item']" @mouseover="arrival('6')" @click="clickBox('6')">
          <div v-if="activeIndex == '6'">
            <img src="@/assets/3公里精准触达2.png" alt="">
          </div>
          <div v-else> <img src="@/assets/3公里精准触达.png" alt=""></div>
        </div>
      </div> -->
    </div>
    <div class="image_item default_three">
      <div class="title">我们的朋友 <span></span> </div>
      <div class="cell_bgc">
        <!-- 第一组 -->
        <div class="hoverBox img1">
          <div class="font">
            <img src="@/assets/l1.png" alt="">
          </div>
          <div class="back">
            <img src="@/assets/l1-1.png" alt="">
          </div>
        </div>
        <!-- 第二组 -->
        <div class="hoverBox img2">
          <div class="font">
            <img src="@/assets/l2.png" alt="">
          </div>
          <div class="back">
            <img src="@/assets/l2-2.png" alt="">
          </div>
        </div>
        <!-- 第三组 -->
        <div class="hoverBox img3">
          <div class="font">
            <img src="@/assets/l3.png" alt="">
          </div>
          <div class="back">
            <img src="@/assets/l3-3.png" alt="">
          </div>
        </div>
        <!-- 第四组 -->
        <div class="hoverBox img4">
          <div class="font">
            <img src="@/assets/l4.png" alt="">
          </div>
          <div class="back">
            <img src="@/assets/l4-4.png" alt="">
          </div>
        </div>
        <!-- 第五组 -->
        <div class="hoverBox img5">
          <div class="font">
            <img src="@/assets/l5.png" alt="">
          </div>
          <div class="back">
            <img src="@/assets/l5-5.png" alt="">
          </div>
        </div>
        <!-- 第六组 -->
        <div class="hoverBox img6">
          <div class="font">
            <img src="@/assets/l6.png" alt="">
          </div>
          <div class="back">
            <img src="@/assets/l6-6.jpeg" alt="">
          </div>
        </div>
        <!-- 第七组 -->
        <div class="hoverBox img7">
          <div class="font">
            <img src="@/assets/l7.png" alt="">
          </div>
          <div class="back">
            <img src="@/assets/l7-7.png" alt="">
          </div>
        </div>
        <!-- 第八组 -->
        <div class="hoverBox img8">
          <div class="font">
            <img src="@/assets/l8.png" alt="">
          </div>
          <div class="back">
            <img src="@/assets/l8-8.jpg" alt="">
          </div>
        </div>
        <!-- 第九组 -->
        <div class="hoverBox img9">
          <div class="font">
            <img src="@/assets/l9.png" alt="">
          </div>
          <div class="back">
            <img src="@/assets/l9-9.png" alt="">
          </div>
        </div>
        <!-- 第十组 -->
        <div class="hoverBox img10">
          <div class="font">
            <img src="@/assets/l10.jpg" alt="">
          </div>
          <div class="back">
            <img src="@/assets/l10-10.png" alt="">
          </div>
        </div>
        <!-- 第十一组 -->
        <div class="hoverBox img11">
          <div class="font">
            <img src="@/assets/l11.jpg" alt="">
          </div>
          <div class="back">
            <img src="@/assets/l11-11.png" alt="">
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Slideshow from "@/components/slideshow/slideshow.vue";
export default {
  name: "HomeView",
  components: {
    Slideshow,
  },
  data() {
    return {
      clickProductIndex: '1',//选中产品
      productIndex: '1',//滑过产品
      clickIndex: '1',//选中盒子
      activeIndex: '1',//滑过盒子
      flag: [false, true, false, false, false],
    };
  },
  watch: {
    productIndex(n, o) {
      this.flag.forEach((v, i) => {
        this.flag[i] = false
      })
      this.flag[n] = true
      this.flag[o] = true

    }
  },
  mounted() {
  },
  methods: {
    //滑出盒子
    mouseoutBox() {
      this.activeIndex = this.clickIndex
    },
    //选中盒子
    clickBox(index) {
      switch (index) {
        case '1': this.clickIndex = index;
          break;
        case '2': this.clickIndex = index;
          break;
        case '3': this.clickIndex = index;
          break;
        case '4': this.clickIndex = index;
          break;
        case '5': this.clickIndex = index;
          break;
        case '6': this.clickIndex = index;
          break;
      }
    },
    //滑入盒子
    arrival(index) {
      console.log(123);
      this.activeIndex = index
    },
    //滑入产品
    product(index) {
      this.productIndex = index
    },
    //滑出产品
    mouseoutProduct() {
      this.productIndex = this.clickProductIndex
    },
    //选中产品
    clickProduct(index) {
      switch (index) {
        case '1': this.clickProductIndex = index;
          break;
        case '2': this.clickProductIndex = index;
          break;
        case '3': this.clickProductIndex = index;
          break;
        case '4': this.clickProductIndex = index;
          break;
        case '5': this.clickProductIndex = index;
          break;
      }
    },
  }
};
</script>
<style scoped lang="scss">
@import "./HomeView.scss";
</style>
