<template>
  <div class="code_container">
    <el-image
      fit="contain"
      src="https://cdn.usharejob.com/uploads/images/2022/02/23/47bf133ae1de875dcb8d2158efa12a47.png"
    ></el-image>
  </div>
</template>

<script>
export default {
  name: "CodeView",
  data() {
    return {};
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./Code.scss";
</style>
