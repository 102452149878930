<template>
  <div class="about_container">
    <div class="image_item">
      <img src="@/assets/商务合作.png" alt="">
      <div class="item1Title">
        商务合作
        <div>Business Cooperation</div>
      </div>
    </div>
    <div class="relevance">
      <div class="item">
        <div><img src="@/assets/市场合作.png" alt=""></div>
        <div>业务合作邮箱</div>
        <p>business@usharejob.com</p>
      </div>
      <div class="item">
        <div><img src="@/assets/推广合作.png" alt=""></div>
        <div>推广合作邮箱</div>
        <p>popu@usharejob.com</p>
      </div>
      <div class="item">
        <div><img src="@/assets/发薪合作.png" alt=""></div>
        <div>发薪服务合作</div>
        <p>SVIP@usharejob.com</p>
      </div>
      <div class="item">
        <div><img src="@/assets/业务合作.png" alt=""></div>
        <div>求职招聘合作</div>
        <p>ujobs@usharejob.com</p>
      </div>
    </div>
    <div class="contact_us">
      <div class="title">·联系我们·</div>
      <div class="messageBox">
        <div class="mapBox">
          <baidu-map class="map" :center="{ lng: 120.806056, lat: 30.746267 }" :zoom="19" :scroll-wheel-zoom="true">
            <bm-overlay pane="labelPane" class="sample" @draw="draw">
              <div>嘉兴优享零工信息技术有限公司</div> 
            </bm-overlay>
          </baidu-map>
        </div>
        <div class="phone">           
          <p> 电话: 0573-83601273</p>
          <p> 负责人: 159 9039 0023</p>
          <p> 邮箱: business@usharejob.com</p>
          <p>地址: 浙江省嘉兴市南湖区建瑞大厦A座1405嘉兴优享零工信息技术有限公司</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      loading: true,
    };
  },
  components: {},
  mounted() {
    setTimeout(() => (this.loading = false), 300);
  },
  methods: {
    draw({ el, BMap, map }) {
      const pixel = map.pointToOverlayPixel(new BMap.Point(120.806056,30.746267))
      el.style.left = pixel.x - 60 + 'px'
      el.style.top = pixel.y - 20 + 'px'
      console.log(el,BMap,map);
    }
  }
};
</script>

<style scoped lang="scss">
@import "./BusinessView.scss";
</style>
