<template>
  <div class="about_container">
    <div class="image_item">
      <img src="@/assets/关于我们.png" alt="">
      <div class="item1Title">
        关于我们
        <div>About us</div>
      </div>
    </div>
    <div class="default_two">
      <div class="imgBox"><img src="@/assets/企业简介.png" alt=""></div>
      <div class="textBox">
        <div>
          优享零工信息技术有限公司，是专注于灵活就业数字化及交易服务的开发运营机构。
          旗下“优享零工”产品，通过SaaS系统服务及社群+平台运营， 解决垂直零工招聘撮合及结算交易服务、旗下的“酷聘直播”产品，
          是一款垂直直播招聘软件， 通过网格化运营，打造区块IP招聘主播，形成区域性影响力的线上招聘媒体。
        </div>
        <div>
          优享零工基于大数据、云计算、人工智能、微服务、uni-app多端开发等前沿技术， 为企业提供灵活用工数字化+人人众包接单+智慧结算服务，构建B2B2C的交易链路实现零工全链路线上完成，
          通过多功能码、无感认证、智慧结算、一键导出等功能。 解决灵工从报名到结算的效率问题和财务问题、通过人脉雷达、团聘、众包接单、私域人才池、智能推送等功能，
          提高招聘推广及撮合效率，提升灵工就业服务能力。自2021年5月运行以来， 聚焦嘉兴地区电商产业链，来自生产、贸易、合储、物流等各环节的电商产业企业客户，
          如乐高、森马、良品铺子、五芳斋、博洋家纺、京东等；生活服务，客户如永辉超市，饿了么，光明牛奶，肯德基，邹大鲜，牛欢食品，
          盒马生鲜等、已超过300家企业入驻优享零工平台，零工注册人数超过5000人。日活近1500人，10000+人在优享零工平台找到工作并完成交付， 单日最高交易量超过300笔。
        </div>

      </div>
    </div>
    <div class="image_item">
      <img src="@/assets/发展历程.png" alt="">
    </div>
    <div class="image_item">
      <img src="@/assets/企业荣誉.png" alt="">
    </div>
  </div>
</template>

<script>
// Import Swiper Vue.js components

export default {
  data() {
    return {
      loading: true,
    };
  },
  components: {},
  mounted() {
    setTimeout(() => (this.loading = false), 300);
  },
};
</script>

<style scoped lang="scss">@import "./AboutView.scss";</style>
