<template>
  <div class="header_container">
    <div class="header_wrap">
      <div class="image_wrap">
        <!-- <el-image
          fit="contain"
          src="https://cdn.usharejob.com/uploads/images/2022/02/22/5dfd2c0121440c3f3feff406b0c50c4b.png"
        ></el-image> -->
        <img src="@/assets/logo.png" alt="">
      </div>
      <div class="tabs_wrap">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          background-color="#fff"
          text-color="#666666"
          active-text-color="#0CD8E2"
          @select="handleSelect"
          router
        >
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/about">关于我们</el-menu-item>
          <el-menu-item index="/business">商务合作</el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderView",
  data() {
    return {
      activeIndex: "/",
    };
  },
  mounted() {
    this.activeIndex = this.$route.path;
  },
  methods: {
    handleSelect(key, keyPath) {
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./Header.scss";
</style>
